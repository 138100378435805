import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    signal,
    ViewChild,
} from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { PricingService } from "../../../services/pricing.service";
import { ICheckoutRequest, IPaymentDetails, PaymentService } from "./payment.service";
import { OrganizationService } from "../../../services/organization.service";
import { Contact } from "../../../pojo/Contact";
import { EmailClass, EmailReport, EmailService } from "../../../services/email.service";
import { S25ItemI } from "../../../pojo/S25ItemI";
import { EventService } from "../../../services/event.service";
import { Debounce } from "../../../decorators/debounce.decorator";
import { S25EditableNumberComponent } from "../../s25-editable/s25-editable-number/s25.editable.number.component";
import { UserprefService } from "../../../services/userpref.service";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { BalanceUpdateService } from "../pricing-org-table-components/s25-pricing-organization/balance.update.service";
import { IntegrationService, IntegrationTypes } from "../../integrations/integration.service";
import { jSith } from "../../../util/jquery-replacement";
import { TelemetryService } from "../../../services/telemetry.service";
import { Telemetry } from "../../../decorators/telemetry.decorator";
import { FlsService } from "../../../services/fls.service";
import { PreferenceService } from "../../../services/preference.service";
import { ContactService } from "../../../services/contact.service";
import { AccessLevels, isMinFls } from "../../../pojo/Fls";
import { Invalidate } from "../../../decorators/cache.decorator";
import { Report } from "../../../pojo/Report";
import { ReportService } from "../../../services/report.service";

@TypeManagerDecorator("s25-ng-payments")
@Component({
    selector: "s25-ng-payments",
    template: ` <div [class.loading]="!init && showHistory()">
            <s25-loading-inline [model]="{}"></s25-loading-inline>
        </div>

        <!-- Sidebar Form -->
        <ng-template #sideBarForm let-data="data">
            @if (remainingBalance > 0) {
                <p>Remaining Balance: {{ remainingBalance | currency }}</p>
                <p>Request Amount: {{ requestAmt | currency }}</p>
            }
            <label>
                Due Date
                <s25-datepicker
                    [modelValue]="data.dueDate"
                    (modelValueChange)="data.dueDate.date = $event"
                ></s25-datepicker>
            </label>
            <label class="payment-type">
                Payment Type
                <select
                    class="cn-form__control"
                    [(ngModel)]="data.type"
                    (ngModelChange)="processRequestAmt(null, data.prop)"
                >
                    <option value="DEPOSIT">Deposit</option>
                    <option value="FINAL">Balance</option>
                    <option value="MISC">Misc</option>
                </select>
            </label>
            <label class="payment-processor">
                Payment Processor&nbsp;
                <select
                    class="cn-form__control"
                    [ngModel]="data.source"
                    (ngModelChange)="onSelectedSourceChange($event)"
                >
                    <option value="null">Select Processor</option>
                    <option [ngValue]="source" *ngFor="let source of sources">{{ source.name }}</option>
                </select>
            </label>
            <label class="attach-invoice">
                Attach Invoice
                @if (reports.length === 0) {
                    Please add an Invoice (Payment) report with this event type
                } @else {
                    <select class="cn-form__control" [(ngModel)]="selectedReport">
                        @for (report of reports; track report.rpt_id) {
                            <option [ngValue]="report">{{ report.rpt_name }}</option>
                        }
                    </select>
                }
            </label>
            <div class="amount-container">
                <div class="radio-wrapper">
                    <s25-ng-radio
                        [(modelValue)]="data.totalType"
                        [value]="'amount'"
                        [name]="'paymentType'"
                        (modelValueChange)="updatePaymentType(data.prop)"
                        >Amount</s25-ng-radio
                    >
                    <s25-ng-radio
                        [(modelValue)]="data.totalType"
                        [value]="'percent'"
                        [name]="'paymentType'"
                        (modelValueChange)="updatePaymentType(data.prop)"
                        >Percentage</s25-ng-radio
                    >
                </div>
                <s25-ng-editable-number
                    [type]="'float'"
                    [max]="data.totalType === 'percent' ? 100 : formatFloat(this.remainingBalance)"
                    [alwaysEditing]="true"
                    [val]="data.totalType === 'percent' ? 100 : this.requestAmt"
                    (valChange)="processRequestAmt($event, data.prop)"
                    (disablingError)="onError($event)"
                ></s25-ng-editable-number>
                <div class="terminal-message" [class.fade-message]="terminalMessage">
                    <s25-ng-icon *ngIf="terminalMessage" [type]="'check'"></s25-ng-icon>
                    {{ terminalMessage }}
                </div>
            </div>
        </ng-template>

        <!-- Main Content -->
        @if (init) {
            <div [class.view-only]="viewOnly">
                @if (!summaryView) {
                    <button class="c-textButton" [class.expanded]="showRequest()" (click)="toggleView('request')">
                        <s25-ng-icon [type]="'caretRight'"></s25-ng-icon>
                        Send Payment Request
                    </button>
                    @if (showRequest()) {
                        <div class="request-wrapper">
                            <fieldset class="amount-form c-margin-bottom--half">
                                <ng-container
                                    *ngTemplateOutlet="sideBarForm; context: { data: requestPaymentData }"
                                ></ng-container>
                            </fieldset>
                            <div class="divider"></div>
                            <fieldset *ngIf="requestAmtEntered" class="email-form">
                                <div class="email-inputs">
                                    <div class="to-email">
                                        <s25-ng-multiselect-search-criteria
                                            [modelBean]="{ textButton: true, title: 'TO' }"
                                            [type]="'contacts'"
                                            [selectedItems]="selectedContacts"
                                            [popoverOnBody]="true"
                                            (changed)="updateEmails()"
                                        ></s25-ng-multiselect-search-criteria>
                                        <input
                                            type="text"
                                            class="c-input"
                                            [(ngModel)]="emails"
                                            aria-label="Enter email addresses for payment request recipients"
                                        />
                                    </div>
                                    <label>
                                        From
                                        <input class="c-input" [(ngModel)]="fromAddress" />
                                    </label>
                                    <label>
                                        Subject
                                        <input class="c-input" [(ngModel)]="emailSubject" />
                                    </label>
                                </div>
                                <label class="email-body">
                                    Message Body
                                    <p class="ngFinePrint c-margin-top--quarter">
                                        Link is added to the email body when request is sent
                                    </p>
                                    <s25-ng-rich-text-editor
                                        [(modelValue)]="emailBody"
                                        [autoResize]="true"
                                    ></s25-ng-rich-text-editor>
                                </label>
                                @if (typeError()) {
                                    <div class="ngRed ngBold c-margin-top--half">{{ typeError() }}</div>
                                }
                                <div class="button-group c-margin-top--half">
                                    <button class="aw-button aw-button--primary" (click)="sendEmail()">
                                        Send Request
                                    </button>
                                </div>
                            </fieldset>
                        </div>
                    }
                }
                <button class="c-textButton" [class.expanded]="showHistory()" (click)="toggleView('history')">
                    <s25-ng-icon [type]="'caretRight'"></s25-ng-icon>
                    Payments
                </button>

                @if (showHistory()) {
                    <div class="history-wrapper" [class.summary-view]="summaryView" [class.view-only]="viewOnly">
                        <div class="payment-table-wrapper">
                            <button class="c-textButton refresh-icon" (click)="reset()">
                                <s25-ng-icon [type]="'refresh'"></s25-ng-icon>
                            </button>
                            @if (noPayments()) {
                                <div class="noResults">No Payment History Found</div>
                            } @else {
                                <table class="table table-bordered ngListTbl ngTable b-listview">
                                    <thead class="ngTableHeader">
                                        <tr class="ngTableRow">
                                            <th class="b-listview-th ngTableCell id-column">ID</th>
                                            <th *ngIf="summaryView" class="b-listview-th ngTableCell">Invoice</th>
                                            <th class="b-listview-th ngTableCell">Create Date</th>
                                            <th
                                                class="b-listview-th ngTableCell"
                                                [class.add-payment]="showAddPaymentRow()"
                                            >
                                                Due Date
                                            </th>
                                            <th class="b-listview-th ngTableCell">Source</th>
                                            <th
                                                class="b-listview-th ngTableCell"
                                                [class.add-payment]="showAddPaymentRow()"
                                            >
                                                Description
                                            </th>
                                            <th
                                                class="b-listview-th ngTableCell"
                                                [class.add-payment]="showAddPaymentRow()"
                                            >
                                                Amount
                                            </th>
                                            <th class="b-listview-th ngTableCell">Type</th>
                                            <th class="b-listview-th ngTableCell">Status</th>
                                            <th class="b-listview-th ngTableCell">Void</th>
                                            <th class="b-listview-th ngTableCell">Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let row of historyRows"
                                            class="b-listview-tr ngListRow ngTableRow"
                                            [attr.aria-label]="row.isVoidRow ? 'Void Payment Form' : null"
                                            [attr.role]="row.isVoidRow ? 'listitem' : null"
                                        >
                                            @if (row.isVoidRow) {
                                                <td colspan="9" style="padding: unset">
                                                    <s25-ng-void-payment
                                                        [voidRow]="row"
                                                        (cancelVoid)="onCancelVoid($event)"
                                                        (voidPayment)="onVoid($event)"
                                                    ></s25-ng-void-payment>
                                                </td>
                                            }

                                            @if (!row.isVoidRow) {
                                                <td class="ngTableCell">{{ row.paymentDetailId }}</td>
                                                <td *ngIf="summaryView" class="ngTableCell">
                                                    <a
                                                        class="c-textButton"
                                                        (click)="onNavToInvoice.emit(row.invoiceId)"
                                                        >{{ row.invoiceName }}</a
                                                    >
                                                </td>
                                                <td class="ngTableCell">{{ row.date }}</td>
                                                <td class="ngTableCell">{{ row.dueDate }}</td>
                                                <td class="ngTableCell">{{ row.source }}</td>
                                                <td class="ngTableCell">{{ row.productDescription }}</td>
                                                <td class="ngTableCell">{{ row.amount }}</td>
                                                <td class="ngTableCell">{{ row.type }}</td>
                                                <td class="ngTableCell">
                                                    @if (
                                                        row.source !== "manual" ||
                                                        row.paymentStatus === "paid" ||
                                                        row.isVoid
                                                    ) {
                                                        <span>{{ row.paymentStatus }}</span
                                                        ><span *ngIf="!row.isVoid && row.paymentStatus === 'paid'"
                                                            >{{ row.customerId ? " by " + row.customerId : ""
                                                            }}{{ row.paymentSuccess ? " on " + row.paymentSuccess : ""
                                                            }}{{
                                                                row.authorizationId
                                                                    ? " with confirmation " + row.authorizationId
                                                                    : ""
                                                            }}</span
                                                        >
                                                    }
                                                    @if (
                                                        row.source === "manual" &&
                                                        row.paymentStatus !== "paid" &&
                                                        !row.isVoid
                                                    ) {
                                                        <button
                                                            *ngIf="!viewOnly && !summaryView"
                                                            class="aw-button aw-button--outline"
                                                            (click)="setPaid(row)"
                                                            [disabled]="row.disabled"
                                                        >
                                                            Mark as Paid
                                                        </button>
                                                        <span *ngIf="viewOnly || summaryView">{{
                                                            row.paymentStatus
                                                        }}</span>
                                                    }
                                                </td>
                                                <td *ngIf="!row.isVoid" class="ngTableCell">
                                                    <button
                                                        *ngIf="
                                                            !viewOnly &&
                                                            !summaryView &&
                                                            (row.source === 'manual' ||
                                                                row.source === 'stripe' ||
                                                                row.source === 'sevenPoint')
                                                        "
                                                        class="aw-button aw-button--outline void-button"
                                                        (click)="showVoidForm(row)"
                                                        [disabled]="row.disabled"
                                                    >
                                                        Void
                                                    </button>
                                                    <span
                                                        *ngIf="
                                                            viewOnly ||
                                                            summaryView ||
                                                            (row.source !== 'manual' &&
                                                                row.source !== 'stripe' &&
                                                                row.source !== 'sevenPoint')
                                                        "
                                                        >Cannot Void</span
                                                    >
                                                </td>
                                                <td *ngIf="row.isVoid" class="ngTableCell">
                                                    Yes{{ row.voidUsername ? " by " + row.voidUsername : ""
                                                    }}{{ row.voidDate ? " on " + row.voidDate : ""
                                                    }}{{ row.voidReason ? " because: " + row.voidReason : "" }}
                                                </td>
                                                <td class="ngTableCell">
                                                    @if (!viewOnly && !summaryView) {
                                                        <s25-ng-editable-textarea
                                                            [val]="row.notes"
                                                            [hasCommit]="true"
                                                            (valChange)="setNotes($event, row.paymentDetailId)"
                                                        ></s25-ng-editable-textarea>
                                                    } @else {
                                                        <div class="view-only-notes">
                                                            {{ row.notes }}
                                                        </div>
                                                    }
                                                </td>
                                            }
                                        </tr>
                                        @if (showAddPaymentRow()) {
                                            <tr class="ngListRow ngTableRow">
                                                <td class="ngTableCell"></td>
                                                <td class="ngTableCell">
                                                    {{ manualPaymentData.createDate | datePipe: datePref }}
                                                </td>
                                                <td class="ngTableCell add-payment">
                                                    <s25-datepicker
                                                        [modelValue]="manualPaymentData.dueDate"
                                                        (modelValueChange)="manualPaymentData.dueDate.date = $event"
                                                    ></s25-datepicker>
                                                </td>
                                                <td class="ngTableCell">manual</td>
                                                <td class="ngTableCell add-payment">
                                                    <textarea
                                                        class="cn-form__control"
                                                        [(ngModel)]="manualPaymentData.description"
                                                    ></textarea>
                                                </td>
                                                <td class="ngTableCell add-payment">
                                                    <div class="amount-container">
                                                        @if (manualPaymentData.totalType === "percent" && !error) {
                                                            <p class="c-margin-bottom--quarter">
                                                                {{ formatFloat(requestAmt) | currency }}
                                                            </p>
                                                        }
                                                        <div class="radio-wrapper">
                                                            <s25-ng-radio
                                                                [(modelValue)]="manualPaymentData.totalType"
                                                                [value]="'amount'"
                                                                [name]="'paymentType'"
                                                                (modelValueChange)="
                                                                    updatePaymentType(manualPaymentData.prop)
                                                                "
                                                                >Amount</s25-ng-radio
                                                            >
                                                            <s25-ng-radio
                                                                [(modelValue)]="manualPaymentData.totalType"
                                                                [value]="'percent'"
                                                                [name]="'paymentType'"
                                                                (modelValueChange)="
                                                                    updatePaymentType(manualPaymentData.prop)
                                                                "
                                                                >Percentage</s25-ng-radio
                                                            >
                                                        </div>
                                                        <s25-ng-editable-number
                                                            [type]="'float'"
                                                            [max]="
                                                                manualPaymentData.totalType === 'percent'
                                                                    ? 100
                                                                    : formatFloat(this.remainingBalance)
                                                            "
                                                            [alwaysEditing]="true"
                                                            [val]="
                                                                manualPaymentData.totalType === 'percent'
                                                                    ? 100
                                                                    : requestAmt
                                                            "
                                                            (valChange)="
                                                                processRequestAmt($event, manualPaymentData.prop)
                                                            "
                                                            (disablingError)="onError($event)"
                                                        ></s25-ng-editable-number>
                                                    </div>
                                                </td>
                                                <td class="ngTableCell">
                                                    <select
                                                        class="cn-form__control"
                                                        [(ngModel)]="manualPaymentData.type"
                                                    >
                                                        <option value="DEPOSIT">Deposit</option>
                                                        <option value="FINAL">Balance</option>
                                                        <option value="MISC">Misc</option>
                                                    </select>
                                                </td>
                                                <td class="ngTableCell">
                                                    <select
                                                        class="cn-form__control"
                                                        [(ngModel)]="manualPaymentData.paymentStatus"
                                                    >
                                                        <option value="paid">Paid</option>
                                                        <option value="unpaid">Unpaid</option>
                                                    </select>
                                                </td>
                                                <td class="ngTableCell">N/A</td>
                                                <td class="ngTableCell">
                                                    <textarea
                                                        class="cn-form__control"
                                                        [(ngModel)]="manualPaymentData.notes"
                                                    ></textarea>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            }
                            @if (typeError() || zeroBalanceWarning()) {
                                <div class="ngRed ngBold">{{ typeError() || zeroBalanceWarning() }}</div>
                            }
                            @if (!summaryView && !viewOnly) {
                                <div class="c-margin-top--half">
                                    @if (showAddPaymentRow()) {
                                        <button
                                            class="aw-button aw-button--primary c-margin-right--single"
                                            (click)="addManualPayment()"
                                        >
                                            Submit Payment
                                        </button>
                                    }
                                    <button class="aw-button aw-button--outline" (click)="toggleAddPaymentRow()">
                                        {{ showAddPaymentRow() ? "Cancel" : "Add Payment" }}
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div *ngIf="alreadyPaid">Paid In Full</div>
            </div>
        }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PaymentsComponent implements OnInit {
    @Input() amountInCents: number;
    @Input() currency: string = "usd";
    @Input() productName: string;
    @Input() productDescription: string;
    @Input() evBillId: number;
    @Input() eventId: number;
    @Input() orgId: number;
    @Input() summaryView: boolean = false;
    @Input() summaryData: PaymentSummaryData;

    @Output() onCreatePayment: EventEmitter<number> = new EventEmitter<number>();
    @Output() onNavToInvoice: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild(S25EditableNumberComponent) editableNumComp: S25EditableNumberComponent;

    sources: PaymentFormData["source"][] = [{ id: "manual", name: "Manual" }];
    init: boolean;
    alreadyPaid: boolean;
    emails: string;
    terminalMessage: string;
    showEmailForm: boolean = false;
    selectedContacts: S25ItemI[];
    orgName: string;
    eventName: string;
    emailSubject: string;
    emailBody: string;
    fromAddress: string;
    requestAmt: number;
    error: boolean;
    typeError = signal("");
    zeroBalanceWarning = signal("");
    requestAmtEntered: boolean = true;
    showHistory = signal(false);
    showRequest = signal(false);
    showAddPaymentRow = signal(false);
    noPayments = signal(false);
    datePref: string;
    historyRows: PaymentHistoryRow[];
    remainingBalance: number;
    totalPayments: number;
    stripeEnabled: boolean;
    sevenPointEnabled: boolean;
    viewOnly: boolean;
    canSendRequest: boolean;
    manualPaymentData: PaymentFormData;
    requestPaymentData: PaymentFormData;
    reports: Report.SimpleObject[];
    selectedReport: Report.SimpleObject;

    constructor(
        private cd: ChangeDetectorRef,
        private elementRef: ElementRef,
        private balanceUpdateService: BalanceUpdateService,
    ) {}

    private setDefaultEmailBody() {
        this.emailBody =
            `<p>` +
            `An invoice has been generated in the amount of ` +
            `<b>${PricingService.formatCurrency(this.requestAmt)}</b> ` +
            `for ` +
            `<b>${this.orgName}</b> ` +
            `regarding billing for ` +
            `<b>${this.eventName}</b>.</p>`;
        if (this.requestPaymentData.source?.id === IntegrationTypes.sevenPoint) {
            this.emailBody +=
                `<p>` +
                `Use the following unique payment identifier on the 7 Point Solutions payment portal: ` +
                `"{{uniquePaymentIdentifier}}". Also use the following Customer Name: "${this.orgName}"` +
                `</p>`;
        }
        if (
            this.requestPaymentData.source?.id === IntegrationTypes.stripe ||
            this.requestPaymentData.source?.id === IntegrationTypes.sevenPoint
        ) {
            this.emailBody += '<p>Click {{paymentLink("here")}} to make a payment.</p>';
        }
    }

    async ngOnInit() {
        S25LoadingApi.init(this.elementRef.nativeElement);

        if (this.summaryView) {
            this.datePref = await UserprefService.getS25Dateformat();
            this.historyRows = (this.mapToRows(this.summaryData) as PaymentHistoryRow[]) ?? [];
        } else {
            await S25Util.all({
                stripeEnabled: IntegrationService.getIntegrationEnabled(IntegrationTypes.stripe),
                sevenPointEnabled: IntegrationService.getIntegrationEnabled(IntegrationTypes.sevenPoint),
                paymentDetails: PaymentService.getFormattedPayments(
                    this.evBillId,
                    this.orgId,
                    this.amountInCents / 100,
                ),
                orgData: OrganizationService.getOrganizationById(this.orgId),
                orgContacts: OrganizationService.getOrganizationContacts(this.orgId),
                eventName: EventService.getEventName(this.eventId),
                datePref: UserprefService.getS25Dateformat(),
                fls: FlsService.getFls(),
                globalFromEmail: PreferenceService.getPreferences(["EmailFromAddress"], "S"),
                userEmail: ContactService.getCurrentEmail(),
                reports: EventService.getEventInvoices(this.eventId),
            }).then((resp) => {
                const paymentPerm = resp?.fls?.MANAGE_PAY;
                this.viewOnly = paymentPerm !== "F";
                this.canSendRequest = isMinFls(paymentPerm, AccessLevels.Read);
                this.reports = [
                    {
                        rpt_name: "No Attachment",
                        rpt_id: 0,
                        rpt_use: undefined,
                        rpt_engine: "DM",
                        object_type: 0,
                    },
                    ...resp.reports,
                ];
                //Start with default payment report
                this.selectedReport =
                    this.reports.find((report) => report.rpt_use === Report.Use.Payment) || this.reports[0];
                this.totalPayments = resp?.paymentDetails?.totalPayments;
                this.remainingBalance = resp?.paymentDetails?.remainingBalance;
                this.requestAmt = this.formatFloat(this.remainingBalance);

                this.stripeEnabled = !this.viewOnly && resp?.stripeEnabled;
                if (
                    this.stripeEnabled &&
                    S25Util.array.findByProp(this.sources, "id", IntegrationTypes.stripe) === -1
                ) {
                    this.sources.push({
                        id: IntegrationTypes.stripe,
                        name: S25Util.firstCharToUpper(IntegrationTypes.stripe),
                    });
                }

                this.sevenPointEnabled = !this.viewOnly && resp?.sevenPointEnabled;
                if (
                    this.sevenPointEnabled &&
                    S25Util.array.findByProp(this.sources, "id", IntegrationTypes.sevenPoint) === -1
                ) {
                    this.sources.push({
                        id: IntegrationTypes.sevenPoint,
                        name: "7 Point Solutions",
                    });
                }

                this.manualPaymentData = {
                    createDate: new Date(),
                    dueDate: { date: new Date() },
                    type: "DEPOSIT",
                    description: "",
                    paymentStatus: "unpaid",
                    totalType: "amount",
                    prop: "manual",
                    notes: "",
                };

                this.requestPaymentData = {
                    dueDate: { date: new Date() },
                    type: "DEPOSIT",
                    source: null,
                    totalType: "amount",
                    prop: "request",
                };

                // we don't show the sources dropdown for only 1 source, so just set it to the one and only available
                if (!this.requestPaymentData.source && this.sources.length === 1) {
                    this.requestPaymentData.source = this.sources[0];
                }

                this.alreadyPaid =
                    resp?.paymentDetails?.evBillId === this.evBillId && this.amountInCents === this.totalPayments;

                this.orgName = resp?.orgData?.organization_name;
                this.eventName = resp?.eventName;
                this.datePref = resp?.datePref;
                this.historyRows = (this.mapToRows(resp?.paymentDetails?.data) as PaymentHistoryRow[]) ?? [];

                this.noPayments.set(this.historyRows.length === 0);
                this.emailSubject = `Payment Request for ${this.eventName}`;
                this.setDefaultEmailBody();
                this.fromAddress = (resp?.globalFromEmail?.EmailFromAddress?.value || resp?.userEmail) ?? "";

                const billingContacts: Contact.DataI[] = resp?.orgContacts?.filter(
                    (contact: Contact.DataI) => contact.contact_role_id === -1,
                );

                this.updateEmails(billingContacts);

                this.selectedContacts =
                    billingContacts?.map((contact) => {
                        return {
                            itemId: contact.contact_id,
                            itemName: contact.contact_name,
                            itemDesc: contact.contact_email,
                        };
                    }) ?? [];
            });
        }

        this.init = true;
        S25LoadingApi.destroy(this.elementRef.nativeElement);
        this.cd.detectChanges();
    }

    async sendEmail() {
        if (!this.requestPaymentData.source) {
            S25Util.showError("A payment processor must be selected");
            return;
        }

        this.terminalMessage = "";
        this.typeError.set("");

        const paymentInCents = this.requestAmt * 100;

        try {
            let cancelUrl = "";
            let successUrl = "";

            if (this.requestPaymentData.source.id === IntegrationTypes.stripe) {
                cancelUrl = window.location.origin + window.location.pathname + "#!/home/payment/cancel";
                successUrl =
                    window.location.origin + window.location.pathname + "#!/home/payment/success/{CHECKOUT_SESSION_ID}";
            }

            const payload: ICheckoutRequest = {
                currency: this.currency,
                amountInCents: paymentInCents,
                productName: this.productName,
                productDescription: this.productDescription,
                cancelUrl: cancelUrl,
                successUrl: successUrl,
                evBillId: this.evBillId,
                organizationId: this.orgId,
                eventId: this.eventId,
                type: this.requestPaymentData.type,
                source: this.requestPaymentData.source.id,
                dueDate:
                    this.requestPaymentData.dueDate?.date &&
                    S25Util.date.toS25ISODateStrStartOfDay(this.requestPaymentData.dueDate.date),
                paymentStatus: "unpaid",
            };

            TelemetryService.sendWithSub(
                "Pricing",
                "Event",
                "PaymentAdd" + S25Util.firstCharToUpper(this.requestPaymentData.source.id),
            );
            let paymentDetails = await PaymentService.createPayment(payload);
            if (paymentDetails?.paymentDetailId) {
                let paymentUrlPromise: Promise<string>;
                if (this.requestPaymentData.source.id === IntegrationTypes.stripe) {
                    paymentUrlPromise = jSith.when(
                        window.location.origin +
                            window.location.pathname +
                            "#!/payment/portal/" +
                            paymentDetails.paymentDetailId,
                    );
                } else if (this.requestPaymentData.source.id === IntegrationTypes.sevenPoint) {
                    paymentUrlPromise = IntegrationService.getIntegration(IntegrationTypes.sevenPoint).then(
                        (integration) => {
                            return integration.url;
                        },
                    );
                }

                let paymentUrl = await paymentUrlPromise;
                let tos: string[] = [];
                if (this.emails) {
                    tos = this.emails
                        .split(/[,;]/)
                        .map((email) => email.trim())
                        .filter((email) => !!email);
                }

                let finalEmailBody = this.emailBody
                    .replace(/{{paymentLink\("(.*?)"\)}}/, `<a href="${paymentUrl}">$1</a>`)
                    .replace("{{uniquePaymentIdentifier}}", `${paymentDetails.paymentDetailId}`);

                const email: EmailClass = {
                    bcc: undefined,
                    cc: undefined,
                    reports: undefined,
                    subject: this.emailSubject,
                    body: finalEmailBody,
                    to: tos,
                    from: this.fromAddress,
                };

                const [resp, error] = await S25Util.Maybe(
                    ReportService.emailPaymentReport(
                        this.eventId,
                        this.evBillId,
                        email,
                        this.selectedReport?.rpt_id ? this.selectedReport : undefined,
                    ),
                );
                if (error) {
                    S25Util.showError(error);
                    return;
                }

                this.terminalMessage = "Emails Sent Successfully";
                this.showEmailForm = false;
                this.cd.detectChanges();
            } else {
                S25Util.showError("Error creating payment details");
            }
        } catch (e) {
            if (e.error?.message === "already_exists") {
                this.displayPaymentTypeError(this.requestPaymentData.type);
            } else {
                S25Util.showError(e);
            }
        }
    }

    toggleView(view: "request" | "history") {
        TelemetryService.sendWithSub("Pricing", "Event", "Payment" + S25Util.firstCharToUpper(view));

        if (view === "request") {
            this.showRequest.update((prev) => {
                if (prev) this.setDefaultEmailBody();
                return !prev;
            });
        } else {
            this.showHistory.update((prev) => !prev);
        }
        // const propData = this.setFormDataByProp(view === "history" ? "manual" : "request");
        // this.requestAmt = this.formatFloat(this.remainingBalance);
        // this.processRequestAmt(propData.totalType === "amount" ? this.requestAmt : 100, propData.prop);
        // view === "request" && this.setDefaultEmailBody();
        // this.typeError = null;
        // this.currentView.update((prevView) => (prevView === view ? null : view));
        // this.cd.detectChanges();
    }

    updateEmails(contacts?: S25ItemI[] | Contact.DataI[]) {
        contacts ??= this.selectedContacts;

        this.emails = contacts
            ?.map((contact: S25ItemI) => contact.itemDesc || contact.contact_email)
            .filter((email) => !!email)
            .join("; ");

        this.cd.detectChanges();
    }

    @Debounce(500)
    processRequestAmt(input: number, dataProp: PaymentFormData["prop"]) {
        const prop = this.setFormDataByProp(dataProp);

        this.requestAmtEntered = false;
        this.cd.detectChanges();

        if (this.error) return;

        input ??= this.requestAmt;
        this.requestAmt = prop.totalType === "percent" ? this.remainingBalance * (input / 100) : input;

        this.showRequest() && this.setDefaultEmailBody();

        this.requestAmtEntered = true;
        this.error = false;

        this.cd.detectChanges();
    }

    updatePaymentType(prop: PaymentFormData["prop"]) {
        const propData = this.setFormDataByProp(prop);
        this.error = false;

        this.editableNumComp.val = propData.totalType === "amount" ? +(this.amountInCents / 100).toFixed(2) : 100;
        this.editableNumComp.errorMessages = [];
        this.editableNumComp.ngOnInit();

        this.processRequestAmt(this.editableNumComp.val, prop);
    }

    onSelectedSourceChange = ($event: any) => {
        this.requestPaymentData.source = $event;
        this.setDefaultEmailBody();
        this.cd.detectChanges();
    };

    onError(error: boolean) {
        this.error = error;
        this.requestAmtEntered = !error;
        this.cd.detectChanges();
    }

    @Telemetry({ category: "Pricing", subCategory: "Event", type: "PaymentAddManual" })
    async addManualPayment() {
        const { dueDate, type, description, paymentStatus, notes } = this.manualPaymentData;
        if (this.requestAmt !== 0) {
            let payload: ICheckoutRequest = {
                productDescription: description,
                amountInCents: this.requestAmt * 100,
                evBillId: this.evBillId,
                organizationId: this.orgId,
                eventId: this.eventId,
                type: type,
                currency: "usd",
                source: "manual",
                successUrl: "",
                cancelUrl: "",
                dueDate: dueDate?.date && S25Util.date.toS25ISODateStrStartOfDay(dueDate.date),
                paymentStatus: paymentStatus,
                notes: encodeURIComponent(notes),
            };
            return PaymentService.createPayment(payload).then(
                () => {
                    paymentStatus === "paid" &&
                        this.balanceUpdateService.updateBalance({ amount: this.requestAmt, orgId: this.orgId });
                    this.onCreatePayment.emit(this.evBillId);
                    this.showAddPaymentRow.set(false);
                    this.typeError.set("");
                    return this.reset();
                },
                (error) => {
                    if (error?.data?.message === "already_exists") {
                        this.displayPaymentTypeError(type);
                        return;
                    } else {
                        S25Util.showError(error);
                    }
                },
            );
        }
    }

    async setPaid(row: PaymentHistoryRow) {
        await PaymentService.setPaid(row.paymentDetailId);
        this.balanceUpdateService.updateBalance({ amount: row.amountTotalCents / 100, orgId: this.orgId });
        await this.reset();
    }

    async showVoidForm(row: PaymentHistoryRow) {
        row.disabled = true;
        let voidRow: PaymentHistoryRow = {
            isVoidRow: true,
            rowToVoid: row,
            amountTotalCents: 0,
            amount: "",
            date: "",
            paymentDetailId: 0,
            disabled: false,
            isVoid: false,
            productDescription: "",
            productName: "",
            source: "",
            status: "",
            paymentStatus: "",
            type: undefined,
        };
        this.historyRows.splice(this.historyRows.indexOf(row) + 1, 0, voidRow);
        this.cd.detectChanges();
    }

    async onVoid(voidRow: PaymentHistoryRow) {
        this.balanceUpdateService.updateBalance({
            amount: -(voidRow.rowToVoid.amountTotalCents / 100),
            orgId: this.orgId,
        });
        await this.reset();
    }

    onCancelVoid(voidRow: PaymentHistoryRow) {
        voidRow.rowToVoid.disabled = false;
        this.historyRows.splice(this.historyRows.indexOf(voidRow), 1); // remove void row
        this.cd.detectChanges();
    }

    displayPaymentTypeError(type: IPaymentDetails["type"]) {
        this.typeError.set(`A ${type} payment already exists. Only one payment of this type is allowed.`);
    }

    @Invalidate({ serviceName: "PaymentService", methodName: "getFormattedPayments" })
    reset() {
        this.init = false;
        this.cd.detectChanges();
        return this.ngOnInit();
    }

    setFormDataByProp(prop: PaymentFormData["prop"]) {
        return this[`${prop}PaymentData`];
    }

    formatFloat(val: number | string) {
        return parseFloat((val as number).toFixed(2));
    }

    setNotes(data: string, id: number) {
        return PaymentService.setPaymentNotes(id, data);
    }

    mapToRows(data: PaymentHistoryRow[]) {
        return data?.map((row) => {
            return S25Util.extend({}, row, {
                type: row.type === "FINAL" ? "BALANCE" : row.type, // FINAL renamed to BALANCE for display purposes
                date: S25Datefilter.transform(row.paymentCreated, this.datePref),
                voidDate: S25Datefilter.transform(row.voidDate, this.datePref),
                paymentSuccess: S25Datefilter.transform(row.paymentSuccess, this.datePref),
                dueDate: S25Datefilter.transform(row.dueDate, this.datePref),
                amount: PricingService.formatCurrency((row.amountTotalCents ?? 0) / 100),
                notes: row.notes ? decodeURIComponent(row.notes) : "",
            });
        });
    }

    toggleAddPaymentRow() {
        if (this.remainingBalance > 0) {
            this.manualPaymentData = {
                createDate: new Date(),
                dueDate: { date: new Date() },
                type: "DEPOSIT",
                description: "",
                paymentStatus: "unpaid",
                totalType: "amount",
                prop: "manual",
                notes: "",
            };
            this.showAddPaymentRow.update((prev) => {
                if (prev) {
                    this.typeError.set("");
                    this.zeroBalanceWarning.set("");
                    this.noPayments.set(this.historyRows.length === 0);
                } else {
                    this.noPayments.set(false);
                }

                return !prev;
            });
        } else {
            this.zeroBalanceWarning.set("The outstanding balance is $0. No payments may be made at this time.");
        }
    }

    protected readonly parseFloat = parseFloat;
}

export interface PaymentHistoryRow extends IPaymentDetails {
    invoiceId?: number;
    invoiceName?: string;
    date?: string;
    amount?: string;
    disabled?: boolean;
    isVoidRow?: boolean;
    rowToVoid?: PaymentHistoryRow;
}

export interface PaymentFormData {
    createDate?: Date; // for manual payment row display purposes only
    description?: string;
    dueDate: { date: Date };
    notes?: string;
    paymentStatus?: "paid" | "unpaid";
    prop: "manual" | "request";
    source?: { id: IntegrationTypes | "manual"; name: string };
    totalType: "amount" | "percent";
    type: IPaymentDetails["type"];
}

export type PaymentSummaryData = (IPaymentDetails & { invoiceId: number; invoiceName: string })[];
