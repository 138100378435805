import { DataAccess } from "../../../dataaccess/data.access";
import { IPaymentDetails } from "./payment.service";

export interface ICheckoutResponse {
    sessionUrl: string;
}

export class StripeService {
    public static async createCheckoutSession(paymentDetailId: number): Promise<ICheckoutResponse> {
        return await DataAccess.post(
            DataAccess.injectCaller(
                "/payments/stripe/session.json?paymentDetailId=" + paymentDetailId,
                "StripeService.createCheckoutSession",
            ),
        );
    }

    public static async sessionSuccess(sessionId: string): Promise<IPaymentDetails> {
        return await DataAccess.post(
            DataAccess.injectCaller(
                "/payments/stripe/payment_details.json?sessionId=" + sessionId,
                "StripeService.sessionSuccess",
            ),
        );
    }
}
