import {
    Component,
    Input,
    Output,
    ElementRef,
    EventEmitter,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnInit,
} from "@angular/core";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { PaymentHistoryRow } from "./s25.payments.component";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { PaymentService } from "./payment.service";
import { S25Util } from "../../../util/s25-util";
import { TelemetryService } from "../../../services/telemetry.service";

@TypeManagerDecorator("s25-ng-void-payment")
@Component({
    selector: "s25-ng-void-payment",
    template: `@if (init) {
        <div class="void-form">
            <div class="void-data">
                <div class="ngBold">Void Amount: {{ voidRow.rowToVoid.amount }}</div>
                <div>
                    <label class="ngBold">
                        Reason
                        <input class="c-input c-margin-left--half" [(ngModel)]="reason" />
                    </label>
                </div>
                <button class="aw-button aw-button--danger--outline" (click)="save()">
                    {{ isSaving ? "" : "Void" }}
                    <s25-loading-inline [model]="{}"></s25-loading-inline>
                </button>
                <button class="aw-button aw-button--outline" (click)="cancel()">Cancel</button>
            </div>
            @if (errorMsg) {
                <div class="ngRed ngBold">{{ errorMsg }}</div>
            }
        </div>
    }`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            :host ::ng-deep .s25-text {
                display: none !important;
            }

            :host ::ng-deep .c-input {
                width: 25em;
            }

            :host ::ng-deep .aw-button.aw-button--danger--outline {
                height: 30px;
                width: 49px;
            }

            :host ::ng-deep .c-loading {
                height: 18px;
            }

            .void-form {
                background-color: #fff;
                padding: 1em;
                box-shadow:
                    inset 0px 11px 8px -10px #ccc,
                    inset 0px -11px 8px -10px #ccc;
            }

            .void-data {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-evenly;
            }
        `,
    ],
})
export class VoidPaymentComponent implements OnInit {
    @Input() voidRow: PaymentHistoryRow;

    @Output() voidPayment: EventEmitter<PaymentHistoryRow> = new EventEmitter<PaymentHistoryRow>();
    @Output() cancelVoid: EventEmitter<PaymentHistoryRow> = new EventEmitter<PaymentHistoryRow>();

    init: boolean;
    reason: string = "";
    errorMsg: string;
    isSaving: boolean;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.init = true;
        this.cd.detectChanges();
    }

    cancel() {
        this.cancelVoid.emit(this.voidRow);
    }

    async save() {
        this.isSaving = true;
        this.cd.detectChanges();
        S25LoadingApi.init(this.elementRef.nativeElement);

        if (!this.reason || this.reason.length > 64) {
            this.errorMsg = "Please enter a void reason less than 65 characters";
            this.isSaving = false;
            S25LoadingApi.destroy(this.elementRef.nativeElement);

            this.cd.detectChanges();
            return;
        }

        try {
            TelemetryService.sendWithSub("Pricing", "Event", "PaymentVoid");
            await PaymentService.voidPayment(this.voidRow.rowToVoid.paymentDetailId, this.reason);
            this.errorMsg = "";
            this.isSaving = false;

            this.cd.detectChanges();
            S25LoadingApi.destroy(this.elementRef.nativeElement);

            this.voidPayment.emit(this.voidRow);
        } catch (e) {
            S25Util.showError(e);
        }
    }
}
